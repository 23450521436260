import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import s from './QcDashboardPage.module.scss';
import { PageDecorator } from 'src/app/decorators';
import { RequestStatus } from 'src/shared/api/types';
import { PreloaderSquared } from 'src/shared/ui/_loaders/PreloaderSquared';
import { useAppDispatch, useAppSelector } from 'src/app/redux/createAction';
import { DashboardWidget } from '../DashboardWidget';
import { ButtonPrimary } from 'src/shared/ui/_buttons/ButtonPrimary';
import { Link } from 'react-router-dom';
import { Preloader } from 'src/shared/ui/_loaders/Preloader';
import { StringInput } from 'src/shared/ui/_inputs/StringInput';
import { Checkbox } from 'src/shared/ui/_inputs/Checkbox';
import { actions } from 'react-table';
import { actionsQcMeta } from '../../_BLL/slice';

const widgets = [
	{
		displayName: 'Missing Country',
		companiesCount: 100,
		peopleCount: '2000',
	},
	{
		displayName: 'Missing Gender',
	},
	{
		displayName: 'Weird Name',
		companiesCount: 100,
	},
	{
		displayName: 'Missing Race',
		peopleCount: '2000',
		companiesCount: 100,
	},
	{
		displayName: 'Strange Educational Institutes Names',
	},
	{
		displayName: 'Missing Gender',
		companiesCount: 100,
	},
	{
		displayName: 'Weird Name',
		peopleCount: 2000,
	},
	{
		displayName: 'Missing Race',
	},
	{
		displayName: 'Strange Educational Institutes Names',
		loadOrder: 1,
	},
];

export const QcDashboardPage: FC = () => {
	const [searchTerm, setSearchTerm] = useState('');
	const [showEmptyPeopleCount, setShowEmptyPeopleCount] = useState(false);
	const [showEmptyCompaniesCount, setShowEmptyCompaniesCount] = useState(false);

	const filteredData = widgets.filter(item => {
		const matchesSearch =
			item.displayName.toLowerCase().includes(searchTerm.toLowerCase()) ||
			(item.companiesCount?.toString().includes(searchTerm) ?? false) ||
			(item.peopleCount?.toString().includes(searchTerm) ?? false);

		const matchesEmptyPeopleCount = showEmptyPeopleCount ? item.peopleCount : true;
		const matchesEmptyCompaniesCount = showEmptyCompaniesCount ? item.companiesCount : true;

		return matchesSearch && matchesEmptyPeopleCount && matchesEmptyCompaniesCount;
	});

	function handleRefreshPage() {
		window.location.reload();
	}

	// * Actions
	const dispatch = useAppDispatch();
	const { getWidgets } = actionsQcMeta;

	useEffect(() => {
		dispatch(getWidgets());
	},[]);
	
	// const status = useAppSelector(state => state.qcDashboard.status);
	return (
		<PageDecorator>
			{/* {status === RequestStatus.loading && <PreloaderSquared className={s.preloader} />} */}
			<div className={s.container}>
				<div className={s.card}>
					<div className={s.header}>
						<h1>QC Dashboard</h1>
						<div className={s.headerButtons}>
							<ButtonPrimary onClick={handleRefreshPage}>Refresh page</ButtonPrimary>
						</div>
					</div>
					<StringInput
						type="text"
						placeholder="search..."
						value={searchTerm}
						onChange={e => setSearchTerm(e.target.value)}
					/>
					<div>
						<Checkbox
							label="Hide missing companies count"
							type="checkbox"
							checked={showEmptyCompaniesCount}
							onChange={() => setShowEmptyCompaniesCount(!showEmptyCompaniesCount)}
						/>
						<Checkbox
							label="Hide missing people count"
							type="checkbox"
							checked={showEmptyPeopleCount}
							onChange={() => setShowEmptyPeopleCount(!showEmptyPeopleCount)}
						/>
					</div>
				</div>
				<div className={s.grid}>
					{filteredData.length > 0 ? (
						filteredData.map((card, index) => (
							<DashboardWidget
								key={index}
								mainText={card.displayName}
								companiesCount={card.companiesCount}
								peopleCount={card.peopleCount}
							/>
						))
					) : (
						<h2>No results</h2>
					)}
				</div>
			</div>
		</PageDecorator>
	);
};
