import { FC } from 'react';
import DotsSVG from 'src/shared/assets/svg/dots_in_circle.svg';
import { Tooltip } from '../Tooltip';
import s from './InfoTooltip.module.scss';

interface Props {
	info: string | string[];
}

export const InfoTooltip: FC<Props> = props => {
	const { info } = props;

	const title =
		typeof info === 'string' ? (
			<span>{info}</span>
		) : (
			<ul>
				{info.map(item => (
					<li key={item}>{item}</li>
				))}
			</ul>
		);

	// * Render
	return (
		<Tooltip title={title}>
			<DotsSVG className={s.icon} />
		</Tooltip>
	);
};
