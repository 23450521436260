import { FC } from 'react';
import s from './DashboardWidget.module.scss';
import { Preloader } from 'src/shared/ui/_loaders/Preloader';

interface Props {
	mainText: string | number;
	companiesCount?: string | number;
	peopleCount?: string | number;
}

export const DashboardWidget: FC<Props> = props => {
	const {
		mainText, //
		companiesCount,
		peopleCount,
	} = props;

	function onClickHandler(): void {
		console.log({ mainText });
	}

	return (
		<div
			className={s.container}
			onClick={onClickHandler}
		>
			<div className={s.preloader}>
				<Preloader />
			</div>

			<h2 className={s.title}>{mainText}</h2>
			<span>
				<p className={s.companies}> {companiesCount && 'Companies:'} 	<b>{companiesCount}</b> </p>
				<p className={s.people}> 	{peopleCount && 'People:'} 			<b>{peopleCount}</b> </p>
			</span>
		</div>
	);
};
