import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { rtkApiRequest } from 'src/shared/api/api';
import { RequestStatus } from 'src/shared/api/types';
import { MetaItem } from './types';

interface State {
	metaData: MetaItem[];
	status: RequestStatus;
}

export const initialState: State = {
	metaData: [],
	status: RequestStatus.still,
};

const getWidgets = createAsyncThunk('metadata/getWidgets', async (args: void, thunkAPI) => {
	return await rtkApiRequest.rtkGETRequest<MetaItem[]>({
		customHost: 'qc',
		url: 'metadata',
		thunkAPI,
	});
});

export const slice = createSlice({
	name: 'qcDashboard_widgets',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getWidgets.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getWidgets.fulfilled, (state, action) => {
			state.metaData = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getWidgets.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsQcMeta = {
	...slice.actions,
	getWidgets,
};
